/* @flow strict-local */

import * as React from "react";
import {createFragmentContainer, graphql, QueryRenderer, type GraphQLQuery} from "react-relay";
import environment from "environment";
import DefinitionList from "components/display/DefinitionList";

import type {GutsPageQuery} from "./__generated__/GutsPageQuery.graphql";

type Props = {||};
export default function GutsPage({}: Props) {
  return (
    <QueryRenderer
      environment={environment}
      query={query}
      render={({error, props}) => {
        if (error) {
          throw error;
        } else if (props) {
          console.log(props);
          const {
            guts: {lastScrape, lastUpdate},
          } = props;
          return (
            <>
              <section>
                <DefinitionList
                  definitions={[
                    {
                      key: "Last scrape",
                      value: lastScrape == null ? "Never!" : formatDateTimeString(lastScrape),
                    },
                    {
                      key: "Last stats update",
                      value: lastUpdate == null ? "Never!" : formatDateTimeString(lastUpdate),
                    },
                  ]}
                />
              </section>
              <section>
                <p>Made in San Francisco, California. The secret ingredient is love.</p>
              </section>
            </>
          );
        } else {
          return <div>Loading</div>;
        }
      }}
    />
  );
}

// Dreadfully, we have to assume for this function that the date looks like this:
// 2019-10-14 01:06:53.453238+00:00
function formatDateTimeString(dateTimeString: string): string {
  // This is the safest way to do. Safari does a poor job of parsing date strings.
  const [datePart, timePart] = dateTimeString.split(" ");
  const [clockTimePart, timeZonePart] = timePart.split("+");
  const [year, month, day] = datePart.split("-").map((part) => parseInt(part, 10));
  const [hour, minutes, seconds] = clockTimePart.split(/[:\.]/).map((part) => parseInt(part, 10));
  return new Date(Date.UTC(year, month - 1, day, hour, minutes, seconds)).toLocaleString();
}

const query: GraphQLQuery<GutsPageQuery> = graphql`
  query GutsPageQuery {
    guts {
      lastScrape
      lastUpdate
    }
  }
`;
