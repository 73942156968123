/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PlayerStatsTable_playerSeasonAggregations$ref: FragmentReference;
declare export opaque type PlayerStatsTable_playerSeasonAggregations$fragmentType: PlayerStatsTable_playerSeasonAggregations$ref;
export type PlayerStatsTable_playerSeasonAggregations = $ReadOnlyArray<{|
  +id: string,
  +player: {|
    +id: string,
    +fullName: string,
  |},
  +draftAge: ?number,
  +gamesPlayed: number,
  +iShots: number,
  +iGoals: number,
  +shootingPerc: ?number,
  +iA1: number,
  +iA2: number,
  +iA: number,
  +goalsFor: number,
  +goalsAgainst: number,
  +goalsForPerc: ?number,
  +toiPerGame: ?number,
  +$refType: PlayerStatsTable_playerSeasonAggregations$ref,
|}>;
export type PlayerStatsTable_playerSeasonAggregations$data = PlayerStatsTable_playerSeasonAggregations;
export type PlayerStatsTable_playerSeasonAggregations$key = $ReadOnlyArray<{
  +$data?: PlayerStatsTable_playerSeasonAggregations$data,
  +$fragmentRefs: PlayerStatsTable_playerSeasonAggregations$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PlayerStatsTable_playerSeasonAggregations",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Player",
      "kind": "LinkedField",
      "name": "player",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "draftAge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamesPlayed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iShots",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iGoals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shootingPerc",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iA1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iA2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iA",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goalsFor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goalsAgainst",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goalsForPerc",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "toiPerGame",
      "storageKey": null
    }
  ],
  "type": "PlayerSeasonAggregation"
};
})();
// prettier-ignore
(node/*: any*/).hash = '60cc70b8b780e10cd93e75e72fb1cbb2';

module.exports = node;
