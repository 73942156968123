/*
 * @flow strict
 **/

import * as React from "react";

type Props = {|
  +size: "title" | "header" | "display" | "small",
  +content: string,
|};
export default function Text({size, content}: Props) {
  if (size === "title") {
    return <h1 className="display-4">{content}</h1>;
  } else if (size === "header") {
    return <h3>{content}</h3>;
  } else if (size === "small") {
    return <small>{content}</small>;
  } else {
    return content;
  }
}
