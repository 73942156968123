// @flow strict

export type Color = $Keys<typeof colors>;
const colors = Object.freeze({
  brand30: "#8463AD",
  brand40: "#643F94",
  brand: "#4F2683",
  brand50: "#4F2683",
  brand60: "#3B166A",
  brand70: "#26064E",

  black: "000000",
});
export default colors;
