// @flow strict

import * as React from "react";

import Select, {type Option} from "components/input/Select";

const ALL_AGES_OPTION_VALUE = "all";
const SELECTABLE_AGES: $ReadOnlyArray<Option> = Object.freeze([
  {
    display: "All",
    value: ALL_AGES_OPTION_VALUE,
  },
  ...[15, 16, 17, 18, 19, 20, 21].map((age) => ({
    display: age.toString(),
    value: age.toString(),
  })),
]);

type Props = {|
  +selectedPlayerDraftAge: ?number,
  onSelect(?number): void,
|};

export default function PlayerDraftAgeFilter({selectedPlayerDraftAge, onSelect}: Props) {
  return (
    <Select
      label="Draft Age"
      options={SELECTABLE_AGES}
      // We map "age" to null
      // ie. when you pick All you're actually not filtering on any playerDraftAge
      value={
        selectedPlayerDraftAge == null ? ALL_AGES_OPTION_VALUE : selectedPlayerDraftAge.toString()
      }
      onSelect={({value}) => onSelect(value === ALL_AGES_OPTION_VALUE ? null : parseInt(value, 10))}
    />
  );
}
