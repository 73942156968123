/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type LeagueFilter_leagues$ref: FragmentReference;
declare export opaque type LeagueFilter_leagues$fragmentType: LeagueFilter_leagues$ref;
export type LeagueFilter_leagues = $ReadOnlyArray<{|
  +id: string,
  +abbreviation: string,
  +$refType: LeagueFilter_leagues$ref,
|}>;
export type LeagueFilter_leagues$data = LeagueFilter_leagues;
export type LeagueFilter_leagues$key = $ReadOnlyArray<{
  +$data?: LeagueFilter_leagues$data,
  +$fragmentRefs: LeagueFilter_leagues$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "LeagueFilter_leagues",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "abbreviation",
      "storageKey": null
    }
  ],
  "type": "League"
};
// prettier-ignore
(node/*: any*/).hash = 'e756ea10da8fbf656fe15dd00af7917f';

module.exports = node;
