/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PlayerBio_player$ref: FragmentReference;
declare export opaque type PlayerBio_player$fragmentType: PlayerBio_player$ref;
export type PlayerBio_player = {|
  +heightInInches: ?number,
  +weightInLbs: ?number,
  +handedness: string,
  +birthDate: ?any,
  +leagueAbbreviation: string,
  +$refType: PlayerBio_player$ref,
|};
export type PlayerBio_player$data = PlayerBio_player;
export type PlayerBio_player$key = {
  +$data?: PlayerBio_player$data,
  +$fragmentRefs: PlayerBio_player$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlayerBio_player",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heightInInches",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weightInLbs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handedness",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "leagueAbbreviation",
      "storageKey": null
    }
  ],
  "type": "Player"
};
// prettier-ignore
(node/*: any*/).hash = 'ad1198542e052f4c058bc58d7c54d63e';

module.exports = node;
