// @flow strict-local
// This is intended to be a wrapper for PlayerProfilePage so that
// if doesn't have to deal with figuring out its URL params

import * as React from "react";
import type {Router, Match} from "found";
import invariant from "invariant";
import PlayerProfilePage from "components/players/PlayerProfilePage";

type Props = {|+match: Match|};
export default function PlayerProfileInterface({match: {params: urlParams}}: Props) {
  const playerId: ?string = urlParams.playerId;
  invariant(playerId != null, "playerId cannot be null on player profile page");
  return <PlayerProfilePage playerId={playerId} />;
}
