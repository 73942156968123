// @flow strict

import * as React from "react";

import NumberInput from "components/input/NumberInput";

type Props = {|
  +gamesPlayed: number,
  +onChange: (number) => void,
|};
export default function GamesPlayedFilter({gamesPlayed, onChange}: Props) {
  return (
    <NumberInput max={60} min={0} label="Minimum GP" value={gamesPlayed} onChange={onChange} />
  );
}
