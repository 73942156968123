/*
 * @flow strict
 */

import * as React from "react";

type Definition = {
  +key: string,
  +value: string,
};
type Props = {|
  +definitions: $ReadOnlyArray<Definition>,
|};
export default function DefinitionList({definitions}: Props) {
  return (
    <dl className="row">
      {definitions.map(({key, value}) => (
        <React.Fragment key={key}>
          <dt className="col-sm-3">{key}</dt>
          <dd className="col-sm-9">{value}</dd>
        </React.Fragment>
      ))}
    </dl>
  );
}
