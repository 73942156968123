/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GutsPageQueryVariables = {||};
export type GutsPageQueryResponse = {|
  +guts: {|
    +lastScrape: ?string,
    +lastUpdate: ?string,
  |}
|};
export type GutsPageQuery = {|
  variables: GutsPageQueryVariables,
  response: GutsPageQueryResponse,
|};
*/


/*
query GutsPageQuery {
  guts {
    lastScrape
    lastUpdate
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Guts",
    "kind": "LinkedField",
    "name": "guts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastScrape",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastUpdate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GutsPageQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GutsPageQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "GutsPageQuery",
    "operationKind": "query",
    "text": "query GutsPageQuery {\n  guts {\n    lastScrape\n    lastUpdate\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e8058c6031f171168694d5aa9cc27d9e';

module.exports = node;
