// @flow strict

import * as React from "react";

import Select, {type Option} from "components/input/Select";
import {narrowValueToEnumOrRaise} from "tools/enumTools";
import REPORT_TYPES, {type ReportTypeEnum} from "components/stats/players/enums/report-types";

type Props = {|+reportType: ReportTypeEnum, +onSelect: (ReportTypeEnum) => void|};

const OPTIONS = Object.freeze([
  {display: "Standard", value: REPORT_TYPES.COUNTING},
  {display: "Per 60 minutes on ice", value: REPORT_TYPES.PER_60},
]);
export default function ReportTypeFilter({reportType, onSelect}: Props) {
  return (
    <Select
      label="Report"
      options={OPTIONS}
      value={reportType}
      onSelect={({value}) => onSelect(narrowValueToEnumOrRaise(REPORT_TYPES, value))}
    />
  );
}
