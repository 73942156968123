// @flow strict
import * as React from "react";

type Props = {|
  +children: React.Node,
  +title?: string,
|};

export default function Card({children, title}: Props) {
  return (
    <div className="card">
      {title != null && <div className="card-header">{title}</div>}
      <div className="card-body">{children}</div>
    </div>
  );
}
