// @flow strict
import {useEffect} from "react";

export default function setTitle(title: ?string) {
  useEffect(() => {
    if (title == null) {
      document.title = `HRF - Junior Hockey Stats`;
    } else {
      document.title = `${title} - HRF - Junior Hockey Stats`;
    }
  }, [title]);
}
