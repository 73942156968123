/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PlayerBio_player$ref = any;
type PlayerStats_seasons$ref = any;
export type PlayerProfilePageQueryVariables = {|
  playerId: string
|};
export type PlayerProfilePageQueryResponse = {|
  +player: ?{|
    +fullName: string,
    +seasonsConnection: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +$fragmentRefs: PlayerStats_seasons$ref
        |}
      |}>
    |},
    +$fragmentRefs: PlayerBio_player$ref,
  |}
|};
export type PlayerProfilePageQuery = {|
  variables: PlayerProfilePageQueryVariables,
  response: PlayerProfilePageQueryResponse,
|};
*/


/*
query PlayerProfilePageQuery(
  $playerId: ID!
) {
  player(id: $playerId) {
    fullName
    ...PlayerBio_player
    seasonsConnection: seasons(sortColumn: "season", sortDirection: "asc") {
      edges {
        node {
          ...PlayerStats_seasons
          id
        }
      }
    }
    id
  }
}

fragment PlayerBio_player on Player {
  heightInInches
  weightInLbs
  handedness
  birthDate
  leagueAbbreviation
}

fragment PlayerStats_seasons on PlayerSeason {
  season
  gamesPlayed
  id
  draftAge
  iShots
  iGoals
  shootingPerc
  iA1
  iA2
  iA
  goalsFor
  goalsAgainst
  goalsForPerc
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "playerId",
    "type": "ID!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "playerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "sortColumn",
    "value": "season"
  },
  {
    "kind": "Literal",
    "name": "sortDirection",
    "value": "asc"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlayerProfilePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "player",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "seasonsConnection",
            "args": (v3/*: any*/),
            "concreteType": "PlayerSeasonConnection",
            "kind": "LinkedField",
            "name": "seasons",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PlayerSeasonEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlayerSeason",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PlayerStats_seasons"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "seasons(sortColumn:\"season\",sortDirection:\"asc\")"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PlayerBio_player"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlayerProfilePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Player",
        "kind": "LinkedField",
        "name": "player",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "heightInInches",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weightInLbs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handedness",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "birthDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "leagueAbbreviation",
            "storageKey": null
          },
          {
            "alias": "seasonsConnection",
            "args": (v3/*: any*/),
            "concreteType": "PlayerSeasonConnection",
            "kind": "LinkedField",
            "name": "seasons",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PlayerSeasonEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlayerSeason",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "season",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gamesPlayed",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "draftAge",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iShots",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iGoals",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shootingPerc",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iA1",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iA2",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "iA",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "goalsFor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "goalsAgainst",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "goalsForPerc",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "seasons(sortColumn:\"season\",sortDirection:\"asc\")"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "PlayerProfilePageQuery",
    "operationKind": "query",
    "text": "query PlayerProfilePageQuery(\n  $playerId: ID!\n) {\n  player(id: $playerId) {\n    fullName\n    ...PlayerBio_player\n    seasonsConnection: seasons(sortColumn: \"season\", sortDirection: \"asc\") {\n      edges {\n        node {\n          ...PlayerStats_seasons\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment PlayerBio_player on Player {\n  heightInInches\n  weightInLbs\n  handedness\n  birthDate\n  leagueAbbreviation\n}\n\nfragment PlayerStats_seasons on PlayerSeason {\n  season\n  gamesPlayed\n  id\n  draftAge\n  iShots\n  iGoals\n  shootingPerc\n  iA1\n  iA2\n  iA\n  goalsFor\n  goalsAgainst\n  goalsForPerc\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c867f62bc8747253de6005c81f26e25b';

module.exports = node;
