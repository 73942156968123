/* @flow strict */

import * as React from "react";
import {StyleSheet, css} from "aphrodite";

type Props = {|
  +type?: "border" | "grow",
  +size?: "s" | "m" | "l",
|};
export default function Spinner({type = "border", size = "m"}: Props) {
  return (
    <div
      className={`${type === "border" ? "spinner-border" : "spinner-grow"} ${css(
        size === "s" && styles.smallSpinner,
        size === "l" && styles.largeSpinner,
      )}`}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
}

const styles = StyleSheet.create({
  smallSpinner: {
    width: "12px",
    height: "12px",
  },
  largeSpinner: {
    width: "72px",
    height: "72px",
  },
});
