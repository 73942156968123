/* @flow strict */

import React from "react";
import invariant from "invariant";
import Select, {type Option} from "components/input/Select";

type Mode = "simple" | "detailed" | "all"; // simple: (EV, PP, PK); detailed: (5v5, etc); all: both

type Props = {|
  +strength: string,
  +mode?: Mode,
  onStrengthChange(string): void,
|};

function range(length: number, start: number = 0): Array<number> {
  invariant(
    parseInt(length, 10) === length && length >= 0,
    "length must be a non-negative integer",
  );
  return [...Array(length).keys()].map((n) => n + start);
}
const SIMPLE_STRENGTH_OPTIONS = [{value: "EV"}, {value: "PP"}, {value: "PK"}];
const DETAILED_STRENGTH_OPTIONS = range(3, 3).flatMap((forStrength) =>
  range(3, 3).map((againstStrength) => ({value: `${forStrength}v${againstStrength}`})),
);

export default function StrengthFilter({strength, onStrengthChange, mode = "all"}: Props) {
  const options = optionsForMode(mode);
  return (
    <Select
      label="Strength"
      options={options}
      value={strength}
      onSelect={({value}) => onStrengthChange(value)}
    />
  );
}

function optionsForMode(mode: Mode): Array<Option> {
  if (mode === "all") {
    return [...SIMPLE_STRENGTH_OPTIONS, ...DETAILED_STRENGTH_OPTIONS];
  } else if (mode === "simple") {
    return SIMPLE_STRENGTH_OPTIONS;
  } else {
    return DETAILED_STRENGTH_OPTIONS;
  }
}
