/*
 * @flow strict-local
 */

import * as React from "react";
import {createFragmentContainer, graphql, QueryRenderer, type GraphQLQuery} from "react-relay";
import environment from "environment";
import setTitle from "hooks/setTitle";
import narrowConnection from "tools/narrowConnection";

import Text from "components/display/Text";
import PlayerBio from "components/players/profile/PlayerBio";
import PlayerStats from "components/players/profile/PlayerStats";

import type {PlayerProfilePageQuery} from "./__generated__/PlayerProfilePageQuery.graphql";

type Props = {|+playerId: string|};
export default function PlayerProfilePage({playerId}: Props) {
  // This is a hack and forces a double render, but it doesn't cause two
  // network requests at this point
  const [playerName, setPlayerName] = React.useState<?string>(null);
  setTitle(playerName);
  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{playerId}}
      render={({error, props}) => {
        if (error) {
          throw error;
        } else if (props) {
          const {player} = props;
          if (player == null) {
            return <div>Player could not be found</div>;
          } else {
            setPlayerName(player.fullName);
            return (
              <>
                <Text size="title" content={player.fullName} />
                <PlayerBio player={player} />
                <PlayerStats seasons={narrowConnection(player.seasonsConnection)} />
              </>
            );
          }
        } else {
          return <div>Loading</div>;
        }
      }}
    />
  );
}

const query: GraphQLQuery<PlayerProfilePageQuery> = graphql`
  query PlayerProfilePageQuery($playerId: ID!) {
    player(id: $playerId) {
      fullName
      ...PlayerBio_player
      seasonsConnection: seasons(sortColumn: "season", sortDirection: "asc") {
        edges {
          node {
            ...PlayerStats_seasons
          }
        }
      }
    }
  }
`;
