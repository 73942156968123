// @flow strict

import * as React from "react";

import NumberInput from "components/input/NumberInput";

type Props = {|
  +toiPerGame: number, // seconds!
  +onChange: (number) => void,
|};
export default function ToiPerGameInput({toiPerGame, onChange}: Props) {
  return (
    <NumberInput
      max={60}
      min={0}
      label="Minimum TOI/GP (minutes)"
      value={toiPerGame / 60}
      onChange={(value) => onChange(value * 60)}
    />
  );
}
