/* @flow strict */

import * as React from "react";
import classNames from "classnames";

type Page<KeyType> = {
  +display?: string,
  +key: KeyType,
  +disabled?: boolean,
};
type Props<KeyType> = {
  +pages: $ReadOnlyArray<Page<KeyType>>,
  +onClick: (KeyType) => void,
};

// https://getbootstrap.com/docs/4.3/components/pagination/
export default function PaginationButtons<KeyType: string | number>({
  pages,
  onClick,
}: Props<KeyType>) {
  return (
    <nav aria-label="Change page">
      <ul className="pagination">
        {pages.map(({display, key, disabled = false}) => (
          <li key={key} className={classNames("page-item", {disabled})}>
            <button className="page-link" onClick={() => onClick(key)} disabled={disabled}>
              {display == null ? key : display}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
}
