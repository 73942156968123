/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PlayerStats_seasons$ref: FragmentReference;
declare export opaque type PlayerStats_seasons$fragmentType: PlayerStats_seasons$ref;
export type PlayerStats_seasons = $ReadOnlyArray<{|
  +season: string,
  +gamesPlayed: number,
  +id: string,
  +draftAge: number,
  +iShots: number,
  +iGoals: number,
  +shootingPerc: ?number,
  +iA1: number,
  +iA2: number,
  +iA: number,
  +goalsFor: number,
  +goalsAgainst: number,
  +goalsForPerc: ?number,
  +$refType: PlayerStats_seasons$ref,
|}>;
export type PlayerStats_seasons$data = PlayerStats_seasons;
export type PlayerStats_seasons$key = $ReadOnlyArray<{
  +$data?: PlayerStats_seasons$data,
  +$fragmentRefs: PlayerStats_seasons$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PlayerStats_seasons",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "season",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamesPlayed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "draftAge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iShots",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iGoals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shootingPerc",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iA1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iA2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iA",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goalsFor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goalsAgainst",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "goalsForPerc",
      "storageKey": null
    }
  ],
  "type": "PlayerSeason"
};
// prettier-ignore
(node/*: any*/).hash = '5d2f10b18cf5180c73766efe42d5760d';

module.exports = node;
