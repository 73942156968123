// @flow strict
import * as React from "react";
import {StyleSheet, css} from "aphrodite";

type Props = {|
  +to: string,
  +children: React.Node,
|};
export default function HeaderLink({to, children}: Props) {
  return <a href={to}>{children}</a>;
}
