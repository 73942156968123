// @flow strict-local

import * as React from "react";
import {graphql} from "react-relay";
import {createFarceRouter, createRender, makeRouteConfig, Route} from "found";
import {BrowserProtocol, queryMiddleware} from "farce";

import DashboardPage from "components/dashboard/DashboardPage";
import PlayersIndexPage from "components/players/PlayersIndexPage";
import PlayerProfileInterface from "components/players/PlayerProfileInterface";
import PlayerStatsInterface, {
  type PlayersStatsInterfaceQueryParams,
} from "components/stats/players/PlayerStatsInterface";
import GutsPage from "components/guts/GutsPage";

const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: makeRouteConfig(
    <Route path="/">
      <Route Component={DashboardPage} />
      <Route path="guts">
        <Route Component={GutsPage} />
      </Route>
      <Route path="players">
        <Route Component={PlayersIndexPage} />
        <Route path=":playerId" Component={PlayerProfileInterface} />
      </Route>
      <Route path="stats">
        <Route path="players" Component={PlayerStatsInterface} />
      </Route>
    </Route>,
  ),
  render: createRender({}),
});

export default Router;
