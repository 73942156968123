/* @flow strict-local */

import * as React from "react";
import {QueryRenderer, graphql, type GraphQLQuery} from "react-relay";
import type {Router, Match} from "found";
import {StyleSheet, css} from "aphrodite";

import environment from "environment";
import PlayerStatsContainer, {type Filters} from "components/stats/players/PlayerStatsContainer";
import Spinner from "umbrella/Spinner";
import type {
  PlayerStatsPageQuery,
  PlayerSeasonAggregationConnectionFilters,
} from "./__generated__/PlayerStatsPageQuery.graphql";
import type {SortDirection} from "components/table/Table";
import type {ReportTypeEnum} from "components/stats/players/enums/report-types";

type Props = {|
  +filters: Filters,
  +onFilterChange: (Filters) => void,
|};

function PlayerStatsPage({filters, onFilterChange}: Props) {
  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={filters}
      render={({error, props: query, retry}) => {
        if (error) {
          return (
            <article>
              <p>
                We encountered a problem. Please report to @leluso on Twitter or email
                justin@brbr.dev. Send him this:
              </p>
              <p>{error.message}</p>
            </article>
          );
        } else if (query) {
          return (
            <PlayerStatsContainer
              playerSeasonAggregationsQuery={query}
              filters={filters}
              onFilterChange={onFilterChange}
            />
          );
        }
        return (
          <div className={css(styles.spinnerWrapper)}>
            <Spinner size="l" />
          </div>
        );
      }}
    />
  );
}

export default PlayerStatsPage;

const styles = StyleSheet.create({
  spinnerWrapper: {
    display: "flex",
    justifyContent: "center",
    height: "1000",
  },
});

const query: GraphQLQuery<PlayerStatsPageQuery> = graphql`
  query PlayerStatsPageQuery(
    $sortColumn: String!
    $sortDirection: String!
    $strength: String!
    $season: String!
    $league: String
    $playerDraftAge: Int
    $after: String
    $report: ReportType!
    $additionalFilters: PlayerSeasonAggregationConnectionFilters!
  ) {
    ...PlayerStatsContainer_playerSeasonAggregationsQuery
      @arguments(
        sortColumn: $sortColumn
        sortDirection: $sortDirection
        strength: $strength
        season: $season
        league: $league
        playerDraftAge: $playerDraftAge
        after: $after
        report: $report
        additionalFilters: $additionalFilters
      )
  }
`;
