/*
 * @flow strict-local
 */

import * as React from "react";
import {createFragmentContainer, graphql, QueryRenderer, type GraphQLQuery} from "react-relay";
import environment from "environment";

import narrowConnection from "tools/narrowConnection";

import type {PlayersIndexPageQuery} from "./__generated__/PlayersIndexPageQuery.graphql";

type Props = {||};
export default function PlayersIndexPage({}: Props) {
  return (
    <QueryRenderer
      environment={environment}
      query={query}
      render={({error, props}) => {
        if (error) {
          throw error;
        } else if (props) {
          const {playersConnection} = props;
          return (
            <ul className="list-group list-group-flush">
              {narrowConnection(playersConnection).map(({id, fullName}) => (
                <a key={id} href={`/players/${id}`} className="list-group-item">
                  {fullName}
                </a>
              ))}
            </ul>
          );
        } else {
          return <div>Loading</div>;
        }
      }}
    />
  );
}

const query: GraphQLQuery<PlayersIndexPageQuery> = graphql`
  query PlayersIndexPageQuery {
    playersConnection: players(sortColumn: "lastName") {
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`;
