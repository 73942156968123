// @flow strict-local

import * as React from "react";
import keyMirror from "keymirror";
import {createFragmentContainer, graphql, type RelayRefetchProp} from "react-relay";
import {StyleSheet, css} from "aphrodite";
import idx from "idx";
import invariant from "invariant";

import narrowConnection from "tools/narrowConnection";
import {descructureRelayId_DONOTUSE} from "tools/relayIdTools";
import PaginationButtons from "umbrella/PaginationButtons";
import PlayerStatsTable from "components/stats/players/PlayerStatsTable";
import StrengthFilter from "components/stats/StrengthFilter";
import SeasonFilter from "components/stats/filters/SeasonFilter";
import LeagueFilter from "components/stats/filters/LeagueFilter";
import PlayerDraftAgeFilter from "components/stats/filters/PlayerDraftAgeFilter";
import ReportTypeFilter from "components/stats/filters/ReportTypeFilter";
import GamesPlayedFilter from "components/stats/filters/GamesPlayedFilter";
import ToiPerGameInput from "components/stats/filters/ToiPerGameInput";
import type {SortDirection} from "components/table/Table";

import type {ReportTypeEnum} from "components/stats/players/enums/report-types";
import type {PlayerStatsContainer_playerSeasonAggregationsQuery as PlayerSeasonAggregationsQuery} from "./__generated__/PlayerStatsContainer_playerSeasonAggregationsQuery.graphql";

type Props = {|
  +playerSeasonAggregationsQuery: PlayerSeasonAggregationsQuery,
  +filters: Filters,
  +onFilterChange: (Filters) => void,
|};

export type Filters = {|
  +sortColumn: string,
  +sortDirection: SortDirection,
  +strength: string,
  +season: string,
  +league: ?string,
  +playerDraftAge: ?number,
  +after: ?string,
  +report: ReportTypeEnum,
  +additionalFilters: {|+minToiPerGame: number, +minGamesPlayed: number|},
|};

class PlayerStatsContainer extends React.Component<Props> {
  handleSort = (sortColumn: string, sortDirection: SortDirection): void => {
    this.props.onFilterChange({...this.props.filters, sortColumn, sortDirection});
  };

  handleStrengthChange = (strength: string): void => {
    this.props.onFilterChange({...this.props.filters, strength});
  };

  handleChangeSeason = (season: string): void => {
    this.props.onFilterChange({...this.props.filters, season});
  };

  handleChangeLeague = (league: ?string): void => {
    this.props.onFilterChange({...this.props.filters, league});
  };

  handleChangePlayerDraftAge = (playerDraftAge: ?number): void => {
    this.props.onFilterChange({...this.props.filters, playerDraftAge});
  };

  handleReportTypeChange = (reportType: ReportTypeEnum): void => {
    this.props.onFilterChange({...this.props.filters, report: reportType});
  };

  goToNextPage = () => {
    const pageInfo = idx(
      this.props.playerSeasonAggregationsQuery.playerSeasonAggregations,
      (_) => _.pageInfo,
    );
    if (pageInfo == null) {
      throw Error("Missing page info");
    }
    this.props.onFilterChange({...this.props.filters, after: pageInfo.endCursor});
  };

  goToPreviousPage = () => {
    const pageInfo = idx(
      this.props.playerSeasonAggregationsQuery.playerSeasonAggregations,
      (_) => _.pageInfo,
    );
    if (pageInfo == null) {
      throw Error("Missing page info");
    }
    invariant(
      pageInfo.startCursor != null,
      "If we can change pages, the results must have a cursor",
    );
    this.props.onFilterChange({
      ...this.props.filters,
      after: getPreviousPageAfterCursor(pageInfo.startCursor),
    });
  };

  render() {
    const {
      playerSeasonAggregationsQuery,
      filters: {
        strength,
        league,
        playerDraftAge,
        season,
        sortColumn,
        sortDirection,
        report,
        additionalFilters: {minToiPerGame, minGamesPlayed},
      },
    } = this.props;
    const playerSeasonAggregations = narrowConnection(
      playerSeasonAggregationsQuery.playerSeasonAggregations,
    );

    if (
      !playerSeasonAggregationsQuery.seasons ||
      !playerSeasonAggregationsQuery.leagues ||
      !playerSeasonAggregationsQuery.playerSeasonAggregations ||
      !playerSeasonAggregationsQuery.playerSeasonAggregations.pageInfo
    ) {
      throw Error("Missing data?");
    }
    const {
      playerSeasonAggregations: {pageInfo},
      seasons,
      leagues,
    } = playerSeasonAggregationsQuery;

    return (
      <>
        <StrengthFilter strength={strength} onStrengthChange={this.handleStrengthChange} />
        <SeasonFilter
          seasons={seasons}
          selectedSeason={season}
          onSelect={this.handleChangeSeason}
        />
        <LeagueFilter
          leagues={leagues}
          selectedLeague={league}
          onSelect={this.handleChangeLeague}
        />
        <PlayerDraftAgeFilter
          selectedPlayerDraftAge={playerDraftAge}
          onSelect={this.handleChangePlayerDraftAge}
        />
        <ReportTypeFilter reportType={report} onSelect={this.handleReportTypeChange} />
        <div className={css(styles.otherFiltersContainer)}>
          <details>
            <summary>Additional Filters</summary>
            <ToiPerGameInput
              toiPerGame={minToiPerGame}
              onChange={(minToiPerGame) =>
                this.props.onFilterChange({
                  ...this.props.filters,
                  additionalFilters: {
                    ...this.props.filters.additionalFilters,
                    minToiPerGame,
                  },
                })
              }
            />
            <GamesPlayedFilter
              gamesPlayed={minGamesPlayed}
              onChange={(minGamesPlayed) =>
                this.props.onFilterChange({
                  ...this.props.filters,
                  additionalFilters: {
                    ...this.props.filters.additionalFilters,
                    minGamesPlayed,
                  },
                })
              }
            />
          </details>
        </div>
        <PlayerStatsTable
          playerSeasonAggregations={playerSeasonAggregations}
          onSort={this.handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
        <div className={css(styles.paginationButtonsContainer)}>
          <PaginationButtons
            pages={[
              {
                display: "Previous",
                key: "previous",
                disabled: !hasPreviousPage(pageInfo.startCursor),
              },
              {display: "Next", key: "next", disabled: !pageInfo.hasNextPage},
            ]}
            onClick={(key) => (key === "previous" ? this.goToPreviousPage() : this.goToNextPage())}
          />
        </div>
      </>
    );
  }
}

export default createFragmentContainer(PlayerStatsContainer, {
  playerSeasonAggregationsQuery: graphql`
    fragment PlayerStatsContainer_playerSeasonAggregationsQuery on Query
      @argumentDefinitions(
        sortColumn: {type: String}
        sortDirection: {type: String}
        strength: {type: String}
        season: {type: String}
        league: {type: String}
        playerDraftAge: {type: Int}
        after: {type: String}
        report: {type: ReportType}
        additionalFilters: {type: PlayerSeasonAggregationConnectionFilters}
      ) {
      playerSeasonAggregations(
        first: 30
        sortColumn: $sortColumn
        sortDirection: $sortDirection
        state: $strength
        leagueAbbreviation: $league
        draftAge: $playerDraftAge
        fromSeason: $season
        toSeason: $season
        after: $after
        report: $report
        additionalFilters: $additionalFilters
      ) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        edges {
          node {
            ...PlayerStatsTable_playerSeasonAggregations
          }
        }
      }
      seasons {
        ...SeasonFilter_seasons
      }
      leagues {
        ...LeagueFilter_leagues
      }
    }
  `,
});

const styles = StyleSheet.create({
  paginationButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  otherFiltersContainer: {
    marginBottom: "1rem",
  },
});

// SUPER HACK
// We absolutely should not be digging into the opaque cursors here
function getPreviousPageAfterCursor(startCursor: string): string {
  const transparentId = atob(startCursor);
  const [type, index] = descructureRelayId_DONOTUSE(startCursor);
  // Say we're currently looking at {startCursor: 60, endCursor: 89}. To get the previous page,
  // which would be {startCursor: 30, endCursor: 59}, we have to request all after 29.
  // So, the formula is startCursor - 30 - 1
  const newIndex = parseInt(index, 10) - 30 - 1;
  return btoa(`${type}:${newIndex}`);
}

function hasPreviousPage(startCursor: ?string): boolean {
  if (startCursor == null) {
    return false;
  }
  const [, index] = descructureRelayId_DONOTUSE(startCursor);
  console.log();
  return parseInt(index, 10) > 0;
}
