// @flow strict

import {Environment, Network, RecordSource, Store} from "relay-runtime";

const store = new Store(new RecordSource());

// A place to log breaking app versions
const APP_VERSIONS: $ReadOnlyArray<{|+slug: string|}> = [
  {
    slug: "initial",
  },
  {
    slug: "bootstrapless-wip",
  },
];

const network = Network.create((operation: {+text: ?string}, variables: {}) => {
  return fetch(
    process.env.NODE_ENV === "production" ? "https://api.hrf.brbr.dev/graphql" : "/graphql",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-HRF-App-Version": APP_VERSIONS[APP_VERSIONS.length - 1].slug,
      },
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    },
  ).then((response) => {
    return response.json();
  });
});

const environment = new Environment({
  network,
  store,
});

export default environment;
