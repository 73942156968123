/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type LeagueFilter_leagues$ref = any;
type PlayerStatsTable_playerSeasonAggregations$ref = any;
type SeasonFilter_seasons$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PlayerStatsContainer_playerSeasonAggregationsQuery$ref: FragmentReference;
declare export opaque type PlayerStatsContainer_playerSeasonAggregationsQuery$fragmentType: PlayerStatsContainer_playerSeasonAggregationsQuery$ref;
export type PlayerStatsContainer_playerSeasonAggregationsQuery = {|
  +playerSeasonAggregations: ?{|
    +pageInfo: {|
      +startCursor: ?string,
      +endCursor: ?string,
      +hasNextPage: boolean,
    |},
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +$fragmentRefs: PlayerStatsTable_playerSeasonAggregations$ref
      |}
    |}>,
  |},
  +seasons: ?$ReadOnlyArray<{|
    +$fragmentRefs: SeasonFilter_seasons$ref
  |}>,
  +leagues: ?$ReadOnlyArray<{|
    +$fragmentRefs: LeagueFilter_leagues$ref
  |}>,
  +$refType: PlayerStatsContainer_playerSeasonAggregationsQuery$ref,
|};
export type PlayerStatsContainer_playerSeasonAggregationsQuery$data = PlayerStatsContainer_playerSeasonAggregationsQuery;
export type PlayerStatsContainer_playerSeasonAggregationsQuery$key = {
  +$data?: PlayerStatsContainer_playerSeasonAggregationsQuery$data,
  +$fragmentRefs: PlayerStatsContainer_playerSeasonAggregationsQuery$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortColumn",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortDirection",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "strength",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "season",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "league",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "playerDraftAge",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "report",
      "type": "ReportType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "additionalFilters",
      "type": "PlayerSeasonAggregationConnectionFilters"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlayerStatsContainer_playerSeasonAggregationsQuery",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "additionalFilters",
          "variableName": "additionalFilters"
        },
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "draftAge",
          "variableName": "playerDraftAge"
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 30
        },
        {
          "kind": "Variable",
          "name": "fromSeason",
          "variableName": "season"
        },
        {
          "kind": "Variable",
          "name": "leagueAbbreviation",
          "variableName": "league"
        },
        {
          "kind": "Variable",
          "name": "report",
          "variableName": "report"
        },
        {
          "kind": "Variable",
          "name": "sortColumn",
          "variableName": "sortColumn"
        },
        {
          "kind": "Variable",
          "name": "sortDirection",
          "variableName": "sortDirection"
        },
        {
          "kind": "Variable",
          "name": "state",
          "variableName": "strength"
        },
        {
          "kind": "Variable",
          "name": "toSeason",
          "variableName": "season"
        }
      ],
      "concreteType": "PlayerSeasonAggregationConnection",
      "kind": "LinkedField",
      "name": "playerSeasonAggregations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PlayerSeasonAggregationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PlayerSeasonAggregation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PlayerStatsTable_playerSeasonAggregations"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Season",
      "kind": "LinkedField",
      "name": "seasons",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SeasonFilter_seasons"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "League",
      "kind": "LinkedField",
      "name": "leagues",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LeagueFilter_leagues"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query"
};
// prettier-ignore
(node/*: any*/).hash = '2c94598b0a73cc91650285a0f421d767';

module.exports = node;
