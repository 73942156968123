/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DashboardTopGoalscorersCard_topGoalScorersQuery$ref: FragmentReference;
declare export opaque type DashboardTopGoalscorersCard_topGoalScorersQuery$fragmentType: DashboardTopGoalscorersCard_topGoalScorersQuery$ref;
export type DashboardTopGoalscorersCard_topGoalScorersQuery = {|
  +topGoalScorers: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +player: {|
          +fullName: string
        |},
        +gamesPlayed: number,
        +iGoals: number,
      |}
    |}>
  |},
  +$refType: DashboardTopGoalscorersCard_topGoalScorersQuery$ref,
|};
export type DashboardTopGoalscorersCard_topGoalScorersQuery$data = DashboardTopGoalscorersCard_topGoalScorersQuery;
export type DashboardTopGoalscorersCard_topGoalScorersQuery$key = {
  +$data?: DashboardTopGoalscorersCard_topGoalScorersQuery$data,
  +$fragmentRefs: DashboardTopGoalscorersCard_topGoalScorersQuery$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardTopGoalscorersCard_topGoalScorersQuery",
  "selections": [
    {
      "alias": "topGoalScorers",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 5
        },
        {
          "kind": "Literal",
          "name": "fromSeason",
          "value": "20192020"
        },
        {
          "kind": "Literal",
          "name": "sortColumn",
          "value": "iGoals"
        },
        {
          "kind": "Literal",
          "name": "sortDirection",
          "value": "desc"
        },
        {
          "kind": "Literal",
          "name": "toSeason",
          "value": "20192020"
        }
      ],
      "concreteType": "PlayerSeasonAggregationConnection",
      "kind": "LinkedField",
      "name": "playerSeasonAggregations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PlayerSeasonAggregationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PlayerSeasonAggregation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Player",
                  "kind": "LinkedField",
                  "name": "player",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "gamesPlayed",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "iGoals",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "playerSeasonAggregations(first:5,fromSeason:\"20192020\",sortColumn:\"iGoals\",sortDirection:\"desc\",toSeason:\"20192020\")"
    }
  ],
  "type": "Query"
};
// prettier-ignore
(node/*: any*/).hash = '61c77a859f35736bde2adb14f819a7a8';

module.exports = node;
