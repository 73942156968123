// @flow strict

import * as React from "react";
import {StyleSheet, css} from "aphrodite";
import {Enum} from "tools/enumTools";

export const SortDirectionsEnum = Enum({
  asc: null,
  desc: null,
});
export type SortDirection = $Values<typeof SortDirectionsEnum>;

export type ColumnDefinition<DataRow> = {
  +columnName: string, // what will be displayed in the table headers
  +sortable?: boolean,
  +defaultSortDirection?: SortDirection,
  +key: string,
  +display?: (DataRow) => React.Node,
  +description?: string,
};

type Props<DataRow> = {|
  +columns: $ReadOnlyArray<ColumnDefinition<DataRow>>,
  +dataRows: $ReadOnlyArray<{+id: string} & DataRow>,
  +sortConfig?: {|
    +onSort: (string, SortDirection) => void,
    +sortColumn: string,
    +sortDirection: SortDirection,
  |},
  +small?: boolean,
  +stickyHeader?: boolean,
|};

const DEFAULT_SORT_DIRECTION: SortDirection = SortDirectionsEnum.desc;

export default function Table<DataRow>({
  dataRows,
  columns,
  sortConfig,
  small = false,
  stickyHeader = false,
}: Props<DataRow>) {
  // TODO(leluso): Invariant to prove that dataRows make sense given columns

  function onHeaderClick({
    key: column,
    defaultSortDirection,
  }: {
    +key: string,
    +defaultSortDirection?: SortDirection,
  }): void {
    if (sortConfig) {
      const {onSort, sortColumn, sortDirection} = sortConfig;
      if (column === sortColumn) {
        onSort(column, flipSortDirection(sortDirection));
      } else {
        onSort(column, defaultSortDirection || DEFAULT_SORT_DIRECTION);
      }
    }
  }

  // only columns where sortable is true or `sortable` is omitted are sortable
  const sortableColumns = columns.filter(({sortable}) => sortable !== false).map(({key}) => key);
  return (
    <table className={`table ${small ? "table-sm" : "table-hover table-striped"}`}>
      <thead className={small ? "" : "thead-dark"}>
        <tr>
          {columns.map((columnDefinition) => (
            <th
              key={columnDefinition.key}
              title={columnDefinition.description}
              onClick={
                sortableColumns.includes(columnDefinition.key)
                  ? () => onHeaderClick(columnDefinition)
                  : undefined
              }
              className={css(
                shouldShowSortDirectionIcon(sortConfig, columnDefinition.key) &&
                  (sortConfig.sortDirection === SortDirectionsEnum.asc
                    ? styles.sortedAsc
                    : styles.sortedDesc),
                styles.cell,
                stickyHeader && styles.tableHeader,
              )}
            >
              {columnDefinition.columnName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataRows.map((rowData) => (
          <tr key={rowData.id}>
            {columns.map(({key, display}) => {
              const displayValue = display == null ? rowData[key] : display(rowData);
              return <td key={`${rowData.id}${key}`}>{displayValue}</td>;
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const styles = StyleSheet.create({
  sortedDesc: {
    ":after": {content: '" ꜜ"'},
  },
  sortedAsc: {
    ":after": {content: '" ꜛ"'},
  },
  cell: {
    width: "48px",
  },
  tableHeader: {
    position: "sticky",
    top: 0,
  },
});

function flipSortDirection(oldSortDirection: SortDirection): SortDirection {
  if (oldSortDirection === SortDirectionsEnum.asc) {
    return SortDirectionsEnum.desc;
  } else {
    return SortDirectionsEnum.desc;
  }
}

function shouldShowSortDirectionIcon(
  sortConfig: ?{+sortColumn: string},
  currentColumn,
): boolean %checks {
  return sortConfig != null && currentColumn === sortConfig.sortColumn;
}
