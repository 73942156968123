// @flow strict-local

import * as React from "react";
import {StyleSheet, css} from "aphrodite";
import {createFragmentContainer, graphql, QueryRenderer, type GraphQLQuery} from "react-relay";
import environment from "environment";
import DashboardTopGoalscorersCard from "components/dashboard/DashboardTopGoalscorersCard";
import narrowConnection from "tools/narrowConnection";

import type {DashboardPageQuery} from "./__generated__/DashboardPageQuery.graphql";

type Props = {||};
export default function DashboardPage({}: Props) {
  return (
    <QueryRenderer
      environment={environment}
      query={query}
      render={({error, props: query}) => {
        if (error) {
          throw error;
        } else if (query) {
          return (
            <div className={css(styles.dashboard)}>
              <section className={css(styles.dashboardSection)}>
                <DashboardTopGoalscorersCard topGoalScorersQuery={query} />
              </section>
            </div>
          );
        } else {
          return <div>Loading</div>;
        }
      }}
    ></QueryRenderer>
  );
}

const styles = StyleSheet.create({
  dashboard: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  dashboardSection: {
    width: "330px",
    padding: "8px",
  },
});

const query: GraphQLQuery<DashboardPageQuery> = graphql`
  query DashboardPageQuery {
    ...DashboardTopGoalscorersCard_topGoalScorersQuery
  }
`;
