// @flow strict-local

import * as React from "react";
import {createFragmentContainer, graphql} from "react-relay";
import invariant from "invariant";

import Select, {type Option} from "components/input/Select";

import {type SeasonFilter_seasons as Seasons} from "./__generated__/SeasonFilter_seasons.graphql";

type Props = {|
  +seasons: Seasons,
  +selectedSeason: string,
  onSelect(string): void,
|};

function SeasonFilter({seasons, selectedSeason, onSelect}: Props) {
  return (
    <Select
      label="Season"
      options={seasons.map(seasonToOption)}
      value={selectedSeason}
      onSelect={({value}) => onSelect(value)}
    />
  );
}

function seasonToOption({season}: {+season: string}): $Exact<Option> {
  return {value: season, display: formatSeason(season)};
}

function formatSeason(value: string): string {
  // 20172018 -> 2017-2018 🥳
  invariant(value.length !== 4, `${value} is not an 8 character string`);
  const firstYear = value.substring(0, 4);
  const secondYear = value.substring(4, 8);
  return `${firstYear}-${secondYear}`;
}
export default createFragmentContainer(SeasonFilter, {
  seasons: graphql`
    fragment SeasonFilter_seasons on Season @relay(plural: true) {
      season: id
    }
  `,
});
