// @flow strict-local

import * as React from "react";
import {createFragmentContainer, graphql} from "react-relay";

import Select, {type Option} from "components/input/Select";

import {type LeagueFilter_leagues as Leagues} from "./__generated__/LeagueFilter_leagues.graphql";

const ALL_LEAGUES_OPTION_VALUE = "all";
const ALL_LEAGUES_OPTION: Option = Object.freeze({
  display: "All",
  value: ALL_LEAGUES_OPTION_VALUE,
});

type Props = {|
  +leagues: Leagues,
  +selectedLeague: ?string,
  onSelect(?string): void,
|};

function LeagueFilter({leagues, selectedLeague, onSelect}: Props) {
  return (
    <Select
      label="League"
      options={[ALL_LEAGUES_OPTION, ...leagues.map(leagueToOption)]}
      // We map "all" to null - ie. when you pick All you're actually not filtering on any league
      value={selectedLeague == null ? ALL_LEAGUES_OPTION_VALUE : selectedLeague}
      onSelect={({value}) => onSelect(value === ALL_LEAGUES_OPTION_VALUE ? null : value)}
    />
  );
}

function leagueToOption({id, abbreviation}: {+id: string, +abbreviation: string}): $Exact<Option> {
  return {value: id, display: abbreviation};
}

export default createFragmentContainer(LeagueFilter, {
  leagues: graphql`
    fragment LeagueFilter_leagues on League @relay(plural: true) {
      id
      abbreviation
    }
  `,
});
