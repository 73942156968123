// @flow strict
import {Enum} from "tools/enumTools";

const REPORT_TYPES = Enum({
  COUNTING: null,
  PER_60: null,
});
export type ReportTypeEnum = $Values<typeof REPORT_TYPES>;

export default REPORT_TYPES;
