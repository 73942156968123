/* @flow strict */

import * as React from "react";
import {UID} from "react-uid";

type Props = {|
  +label?: string,
  +value: string,
  +onChange: (string) => void,
|};

export default class TextInput extends React.PureComponent<Props> {
  render() {
    const {label, value} = this.props;

    return (
      <UID>
        {(id: string) => (
          <div className="input-group mb-3">
            {label != null && (
              <div className="input-group-prepend">
                <label className="input-group-text" htmlFor={id}>
                  {label}
                </label>
              </div>
            )}
            <input
              type="text"
              // class="form-control"
              id={id}
              value={value}
              onChange={(event: SyntheticEvent<HTMLInputElement>) => event.currentTarget.value}
            />
          </div>
        )}
      </UID>
    );
  }
}
