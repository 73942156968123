// @flow strict
import keyMirror from "keymirror";
import invariant from "invariant";

export function Enum<T: {}>(definition: T): $ObjMap<T, <V>(V) => $Keys<T>> {
  return Object.freeze(keyMirror(definition));
}

/* eslint-disable relay/no-future-added-value */
export function nullifyFutureAddedValue<T: {}>(
  enumToCheck: T,
  value: $Keys<T> | "%future added value",
): ?$Keys<T> {
  narrowValueToEnumOrNull(enumToCheck, value);
}
/* eslint-enable relay/no-future-added-value */

export function narrowValueToEnumOrRaise<T: {}>(enumToCheck: T, value: string): $Keys<T> {
  const narrowedValue = narrowValueToEnumOrNull(enumToCheck, value);
  if (narrowedValue != null) {
    return narrowedValue;
  }

  throw Error("enum does not contain value");
}

export function narrowValueToEnumOrNull<T: {}>(enumToCheck: T, value: string): ?$Keys<T> {
  const enumKeys: $ReadOnlyArray<$Keys<typeof enumToCheck>> = Object.keys(enumToCheck);
  if (enumKeys.includes(value)) {
    // $FlowFixMe this doesn't work
    return value;
  }
  return null;
}
