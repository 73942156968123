// @flow strict-local

import React from "react";
import ReactDOM from "react-dom";

import invariant from "invariant";
// import "bootstrap/dist/css/bootstrap.css";
// $FlowFixMe(leluso): I don't know why Flow won't let me import this
import "./css-files/main.scss";

import App from "components/App";

const domElement = document.getElementById("root");
invariant(domElement != null, "root DOM element must exist");
ReactDOM.render(<App />, domElement);
