// @flow strict

import * as React from "react";
import {StyleSheet, css} from "aphrodite";
import NavbarDropdown from "components/navigation/NavbarDropdown";
import logo from "brand/Black logo - no background.svg";
import Image from "umbrella/Image";
import Link from "components/Link";
import TextInput from "components/input/TextInput";
import Button from "components/Button";
import Group from "umbrella/Group";
import whitespaceSizes from "umbrella/styles/whitespaceSizes";
import colors from "umbrella/styles/colors";

export default function Header() {
  const [searchString, handleChangeSearchString] = React.useState<string>("");
  return (
    <BaseHeader
      logo={logo}
      links={[
        {to: "/", label: "Home"},
        {to: "/stats/players", label: "Player Stats"},
      ]}
      search={{onSearch: () => {}, value: searchString, onValueChange: handleChangeSearchString}}
    />
  );
}

type BaseHeaderProps = {|
  +logo: string,
  +links: $ReadOnlyArray<{+to: string, +label: string, ...}>,
  +search?: {|+onSearch: () => void, +value: string, +onValueChange: (string) => void|},
|};
function BaseHeader({logo, links, search}: BaseHeaderProps) {
  return (
    <header className={css(styles.header)}>
      <nav className={css(styles.navigationContainer)}>
        <Group gapSize="m">
          <Link to="/">
            <Image source={logo} height={30} />
          </Link>
          <Group gapSize="m">
            {links.map(({to, label}) => (
              <Link key={to} to={to}>
                {label}
              </Link>
            ))}
          </Group>
        </Group>
        {search && (
          <Group gapSize="m">
            <TextInput value={search.value} onChange={search.onValueChange} />
            <Button onClick={search.onSearch}>Search</Button>
          </Group>
        )}
      </nav>
    </header>
  );
}

const styles = StyleSheet.create({
  logo: {
    display: "inline-block",
    paddingRight: whitespaceSizes.m,
  },
  navigationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  header: {
    borderBottomColor: colors.brand,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    marginBottom: whitespaceSizes.m,
  },
});
