/* @flow strict-local */

import * as React from "react";
import {createFragmentContainer, graphql} from "react-relay";

import DefinitionList from "components/display/DefinitionList";

import type {PlayerBio_player as Player} from "./__generated__/PlayerBio_player.graphql";

type Props = {|
  +player: Player,
|};
function PlayerBio({
  player: {heightInInches, weightInLbs, handedness, birthDate, leagueAbbreviation},
}: Props) {
  const definitions = [
    {key: "League", value: leagueAbbreviation},
    {key: "Handedness", value: handedness},
  ];
  if (birthDate != null) {
    definitions.push({key: "DOB", value: birthDate});
  }
  if (heightInInches != null) {
    definitions.push({key: "Height", value: `${heightInInches} in.`});
  }
  if (weightInLbs != null) {
    definitions.push({key: "Weight", value: `${weightInLbs} lbs.`});
  }

  return <DefinitionList definitions={definitions} />;
}

export default createFragmentContainer(PlayerBio, {
  player: graphql`
    fragment PlayerBio_player on Player {
      heightInInches
      weightInLbs
      handedness
      birthDate
      leagueAbbreviation
    }
  `,
});
