// @flow strict
import * as React from "react";
import {StyleSheet, css} from "aphrodite";

type Props = {|
  +source: string,
  +height?: number,
  +width?: number,
|};
export default function Image({source, height, width}: Props) {
  return <img src={source} height={height} width={width} />;
}
