// @flow strict

export default function narrowConnection<NodeType: {}>(
  connection: ?{
    +edges: ?$ReadOnlyArray<?{+node: ?NodeType}>,
  },
): $ReadOnlyArray<NodeType> {
  return ((connection && connection.edges) || [])
    .filter(Boolean)
    .map((edge) => edge.node)
    .filter(Boolean);
}
