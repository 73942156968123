/* @flow strict */

import * as React from "react";
import {UID} from "react-uid";

type Props = {|
  +label?: string,
  +value: ?number,
  +max?: number,
  +min?: number,
  +step?: number,
  +onChange: (number) => void,
|};

export default class NumberInput extends React.PureComponent<Props> {
  handleSelect = (event: SyntheticEvent<HTMLInputElement>): void => {
    const {onChange} = this.props;
    onChange(parseFloat(event.currentTarget.value));
  };

  render() {
    const {label, value, max, min, step} = this.props;

    return (
      <UID>
        {(id: string) => (
          <div className="input-group mb-3">
            {label != null && (
              <div className="input-group-prepend">
                <label className="input-group-text" htmlFor={id}>
                  {label}
                </label>
              </div>
            )}
            <input
              type="number"
              className="form-control"
              id={id}
              max={max}
              min={min}
              step={step}
              value={value}
              onChange={this.handleSelect}
            />
          </div>
        )}
      </UID>
    );
  }
}
