// @flow strict

import * as React from "react";
import {StyleSheet, css} from "aphrodite";
import whitespaceSizes, {type Size} from "umbrella/styles/whitespaceSizes";

type Props = {|+children: React.Node, ...StyleProps|};
export default function Group({children, ...styleProps}: Props) {
  const styles = getStyles(styleProps);
  return (
    <div className={css(styles.group)}>
      {React.Children.map(children, (child: React.Node) => (
        <div className={css(styles.child)}>{child}</div>
      ))}
    </div>
  );
}

type CrossAxisAlignment = "start" | "end" | "center" | "stretch" | "baseline";

type StyleProps = {|
  +direction?: "row" | "column",
  +gapSize?: Size,
  +crossAxisAlignment?: CrossAxisAlignment,
|};
function getStyles({
  direction = "row",
  gapSize = "none",
  crossAxisAlignment = "center",
}: StyleProps) {
  return StyleSheet.create({
    group: {
      display: "flex",
      flexDirection: direction,
      alignItems: getGroupAlignItems(crossAxisAlignment),
    },
    child: {
      ":not(:first-child)": {
        paddingLeft: whitespaceSizes[gapSize],
      },
    },
  });
}

function getGroupAlignItems(
  crossAxisAlignment: CrossAxisAlignment,
): "flex-start" | "flex-end" | "center" | "stretch" | "baseline" {
  switch (crossAxisAlignment) {
    case "start":
      return "flex-start";
    case "end":
      return "flex-start";
    default:
      return crossAxisAlignment;
  }
}
