// @flow strict
import * as React from "react";

import Table from "components/table/Table";
import Card from "components/display/Card";

type Props = {|
  +objectType: "Player" | "Team",
  +dataRows: $ReadOnlyArray<{+object: string, +id: string, +[string]: string}>,
  +statColumns: $ReadOnlyArray<{+columnName: string, +key: string}>,
  +title: string,
|};

export default function DashboardTableCard({objectType, dataRows, title, statColumns}: Props) {
  return (
    <Card title={title}>
      <Table
        small={true}
        columns={[
          {
            columnName: objectType,
            key: "object",
            sortable: false,
          },
          ...statColumns.map((column) => ({...column, sortable: false})),
        ]}
        dataRows={dataRows}
      />
    </Card>
  );
}
