// @flow strict-local

import React from "react";
import {createFragmentContainer, graphql} from "react-relay";
import Table, {type SortDirection, type ColumnDefinition} from "components/table/Table";
import {Link} from "found";
import type {PlayerStatsTable_playerSeasonAggregations as PlayerSeasonAggregations} from "./__generated__/PlayerStatsTable_playerSeasonAggregations.graphql";

const UNKNOWN = "🥶";
type Props = {|
  +playerSeasonAggregations: PlayerSeasonAggregations,
  +sortDirection: SortDirection,
  +sortColumn: string,
  +onSort: (string, SortDirection) => void,
|};

const COLUMNS = [
  {
    columnName: "Name",
    key: "playerName",
    sortable: false,
    type: "link",
    url: ({id}: {+id: string}) => `/players/${id}`,
    display: ({player: {id, fullName}}: {+player: {+fullName: string, +id: string}}) => (
      <Link to={`/players/${id}`}>{fullName}</Link>
    ),
  },
  {columnName: "Age", key: "draftAge", sortable: false},
  {columnName: "GP", key: "gamesPlayed"},
  {columnName: "S", key: "iShots"},
  {columnName: "G", key: "iGoals"},
  {columnName: "A1", key: "iA1"},
  {columnName: "A2", key: "iA2"},
  {columnName: "A", key: "iA"},
  {columnName: "GF", key: "goalsFor"},
  {columnName: "GA", key: "goalsAgainst"},
  {columnName: "GF%", key: "goalsForPerc"},
  {columnName: "S%", key: "shootingPerc"},
  {
    columnName: "ℓTOI/GP",
    key: "toiPerGame",
    type: "text",
    description: `Time on ice per game played, as estimated by a linear formula that assigns TOI
      proportional to the number of team events the player has participated in.
      We anticipate good players will be assigned too much time and bad players too little.
      `.replace(/\s{2,}/g, " "),
  },
];
class PlayerStatsTable extends React.Component<Props> {
  render() {
    const {playerSeasonAggregations, sortColumn, sortDirection, onSort} = this.props;
    if (playerSeasonAggregations.length) {
      return (
        <Table
          dataRows={playerSeasonAggregations.map(aggregationToRow)}
          columns={COLUMNS}
          sortConfig={{
            onSort: onSort,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          }}
          stickyHeader={true}
        />
      );
    } else {
      return <div>No data</div>;
    }
  }
}
export default createFragmentContainer(PlayerStatsTable, {
  playerSeasonAggregations: graphql`
    fragment PlayerStatsTable_playerSeasonAggregations on PlayerSeasonAggregation
      @relay(plural: true) {
      id
      player {
        id
        fullName
      }
      draftAge
      gamesPlayed
      iShots
      iGoals
      shootingPerc
      iA1
      iA2
      iA
      goalsFor
      goalsAgainst
      goalsForPerc
      toiPerGame
    }
  `,
});

function aggregationToRow({
  id,
  player,
  draftAge,
  gamesPlayed,
  iShots,
  iGoals,
  shootingPerc,
  iA1,
  iA2,
  iA,
  goalsFor,
  goalsAgainst,
  goalsForPerc,
  toiPerGame,
}: {
  +id: string,
  +player: {+id: string, +fullName: string},
  +draftAge: ?number,
  +gamesPlayed: number,
  +iShots: number,
  +iGoals: number,
  +shootingPerc: ?number,
  +iA1: number,
  +iA2: number,
  +iA: number,
  +goalsFor: number,
  +goalsAgainst: number,
  +goalsForPerc: ?number,
  +toiPerGame: ?number,
}): {|
  +id: string,
  +player: {+id: string, +fullName: string},
  +draftAge: string,
  +gamesPlayed: string,
  +iShots: string,
  +iGoals: string,
  +shootingPerc: string,
  +iA1: string,
  +iA2: string,
  +iA: string,
  +goalsFor: string,
  +goalsAgainst: string,
  +goalsForPerc: string,
  +toiPerGame: string,
|} {
  return {
    id,
    player,
    draftAge: draftAge == null ? UNKNOWN : draftAge.toString(10),
    gamesPlayed: gamesPlayed.toString(10),
    iShots: formatNumericForDisplay(iShots),
    iGoals: formatNumericForDisplay(iGoals),
    shootingPerc: shootingPerc != null ? formatPercForDisplay(shootingPerc) : UNKNOWN,
    iA1: formatNumericForDisplay(iA1),
    iA2: formatNumericForDisplay(iA2),
    iA: formatNumericForDisplay(iA),
    goalsFor: formatNumericForDisplay(goalsFor),
    goalsAgainst: formatNumericForDisplay(goalsAgainst),
    goalsForPerc: goalsForPerc != null ? formatPercForDisplay(goalsForPerc) : UNKNOWN,
    toiPerGame: toiPerGame != null ? formatToi(toiPerGame) : UNKNOWN,
  };
}
function formatPercForDisplay(perc: number): string {
  const fixedLength = perc.toFixed(2);
  return `${fixedLength}%`;
}
function formatNumericForDisplay(numeric: number): string {
  if (Number.isInteger(numeric)) {
    return numeric.toString(10);
  } else {
    return numeric.toFixed(2);
  }
}
function formatToi(toi: number): string {
  // TOI is in seconds
  const minutes = Math.floor(toi / 60);
  const seconds = Math.round(toi % 60);
  return `${minutes}:${seconds.toString(10).padStart(2, "0")}`;
}
