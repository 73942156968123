/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PlayerStatsContainer_playerSeasonAggregationsQuery$ref = any;
export type ReportType = "COUNTING" | "PER_60" | "%future added value";
export type PlayerSeasonAggregationConnectionFilters = {|
  minToiPerGame?: ?number,
  minGamesPlayed?: ?number,
|};
export type PlayerStatsPageQueryVariables = {|
  sortColumn: string,
  sortDirection: string,
  strength: string,
  season: string,
  league?: ?string,
  playerDraftAge?: ?number,
  after?: ?string,
  report: ReportType,
  additionalFilters: PlayerSeasonAggregationConnectionFilters,
|};
export type PlayerStatsPageQueryResponse = {|
  +$fragmentRefs: PlayerStatsContainer_playerSeasonAggregationsQuery$ref
|};
export type PlayerStatsPageQuery = {|
  variables: PlayerStatsPageQueryVariables,
  response: PlayerStatsPageQueryResponse,
|};
*/


/*
query PlayerStatsPageQuery(
  $sortColumn: String!
  $sortDirection: String!
  $strength: String!
  $season: String!
  $league: String
  $playerDraftAge: Int
  $after: String
  $report: ReportType!
  $additionalFilters: PlayerSeasonAggregationConnectionFilters!
) {
  ...PlayerStatsContainer_playerSeasonAggregationsQuery_bIuJi
}

fragment LeagueFilter_leagues on League {
  id
  abbreviation
}

fragment PlayerStatsContainer_playerSeasonAggregationsQuery_bIuJi on Query {
  playerSeasonAggregations(first: 30, sortColumn: $sortColumn, sortDirection: $sortDirection, state: $strength, leagueAbbreviation: $league, draftAge: $playerDraftAge, fromSeason: $season, toSeason: $season, after: $after, report: $report, additionalFilters: $additionalFilters) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...PlayerStatsTable_playerSeasonAggregations
        id
      }
    }
  }
  seasons {
    ...SeasonFilter_seasons
  }
  leagues {
    ...LeagueFilter_leagues
  }
}

fragment PlayerStatsTable_playerSeasonAggregations on PlayerSeasonAggregation {
  id
  player {
    id
    fullName
  }
  draftAge
  gamesPlayed
  iShots
  iGoals
  shootingPerc
  iA1
  iA2
  iA
  goalsFor
  goalsAgainst
  goalsForPerc
  toiPerGame
}

fragment SeasonFilter_seasons on Season {
  season: id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortColumn",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "strength",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "season",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "league",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "playerDraftAge",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "report",
    "type": "ReportType!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "additionalFilters",
    "type": "PlayerSeasonAggregationConnectionFilters!"
  }
],
v1 = {
  "kind": "Variable",
  "name": "additionalFilters",
  "variableName": "additionalFilters"
},
v2 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v3 = {
  "kind": "Variable",
  "name": "report",
  "variableName": "report"
},
v4 = {
  "kind": "Variable",
  "name": "sortColumn",
  "variableName": "sortColumn"
},
v5 = {
  "kind": "Variable",
  "name": "sortDirection",
  "variableName": "sortDirection"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlayerStatsPageQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "league",
            "variableName": "league"
          },
          {
            "kind": "Variable",
            "name": "playerDraftAge",
            "variableName": "playerDraftAge"
          },
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "season",
            "variableName": "season"
          },
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "strength",
            "variableName": "strength"
          }
        ],
        "kind": "FragmentSpread",
        "name": "PlayerStatsContainer_playerSeasonAggregationsQuery"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlayerStatsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "draftAge",
            "variableName": "playerDraftAge"
          },
          {
            "kind": "Literal",
            "name": "first",
            "value": 30
          },
          {
            "kind": "Variable",
            "name": "fromSeason",
            "variableName": "season"
          },
          {
            "kind": "Variable",
            "name": "leagueAbbreviation",
            "variableName": "league"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "state",
            "variableName": "strength"
          },
          {
            "kind": "Variable",
            "name": "toSeason",
            "variableName": "season"
          }
        ],
        "concreteType": "PlayerSeasonAggregationConnection",
        "kind": "LinkedField",
        "name": "playerSeasonAggregations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PlayerSeasonAggregationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PlayerSeasonAggregation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Player",
                    "kind": "LinkedField",
                    "name": "player",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "draftAge",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gamesPlayed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iShots",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iGoals",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shootingPerc",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iA1",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iA2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iA",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "goalsFor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "goalsAgainst",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "goalsForPerc",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "toiPerGame",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Season",
        "kind": "LinkedField",
        "name": "seasons",
        "plural": true,
        "selections": [
          {
            "alias": "season",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "League",
        "kind": "LinkedField",
        "name": "leagues",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "abbreviation",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "PlayerStatsPageQuery",
    "operationKind": "query",
    "text": "query PlayerStatsPageQuery(\n  $sortColumn: String!\n  $sortDirection: String!\n  $strength: String!\n  $season: String!\n  $league: String\n  $playerDraftAge: Int\n  $after: String\n  $report: ReportType!\n  $additionalFilters: PlayerSeasonAggregationConnectionFilters!\n) {\n  ...PlayerStatsContainer_playerSeasonAggregationsQuery_bIuJi\n}\n\nfragment LeagueFilter_leagues on League {\n  id\n  abbreviation\n}\n\nfragment PlayerStatsContainer_playerSeasonAggregationsQuery_bIuJi on Query {\n  playerSeasonAggregations(first: 30, sortColumn: $sortColumn, sortDirection: $sortDirection, state: $strength, leagueAbbreviation: $league, draftAge: $playerDraftAge, fromSeason: $season, toSeason: $season, after: $after, report: $report, additionalFilters: $additionalFilters) {\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        ...PlayerStatsTable_playerSeasonAggregations\n        id\n      }\n    }\n  }\n  seasons {\n    ...SeasonFilter_seasons\n  }\n  leagues {\n    ...LeagueFilter_leagues\n  }\n}\n\nfragment PlayerStatsTable_playerSeasonAggregations on PlayerSeasonAggregation {\n  id\n  player {\n    id\n    fullName\n  }\n  draftAge\n  gamesPlayed\n  iShots\n  iGoals\n  shootingPerc\n  iA1\n  iA2\n  iA\n  goalsFor\n  goalsAgainst\n  goalsForPerc\n  toiPerGame\n}\n\nfragment SeasonFilter_seasons on Season {\n  season: id\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '873d0eca127432cfb9575236b66354fc';

module.exports = node;
