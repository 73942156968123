// @flow strict-local

import * as React from "react";
import type {Router, Match} from "found";

import PlayerStatsPage from "components/stats/players/PlayerStatsPage";
import {type Filters} from "components/stats/players/PlayerStatsContainer";
import ReportTypes from "components/stats/players/enums/report-types";
import {narrowValueToEnumOrNull} from "tools/enumTools";
import {SortDirectionsEnum, type SortDirection} from "components/table/Table";
import type {ReportTypeEnum} from "components/stats/players/enums/report-types";

export type PlayersStatsInterfaceQueryParams = {|
  +sortColumn: string,
  +sortDirection: SortDirection,
  +strength: string,
  +season: string,
  +league: ?string,
  +playerDraftAge: ?number,
  +after: ?string,
  +report: ReportTypeEnum,
  +minToiPerGame: number,
  +minGamesPlayed: number,
|};
type Props = {|
  +router: Router<PlayersStatsInterfaceQueryParams>,
  +match: Match,
|};

export default function PlayerStatsInterface({router, match: {location}}: Props) {
  function handleFilterChange(filters: Filters) {
    const {additionalFilters, ...otherPageFunctions} = filters;
    // TODO(leluso): We should move data filters like draft age into dataFilters, and leave
    // functional things like pagination and sorting in otherPageFunctions
    const {
      additionalFilters: _pluckedAndUnusedCleanedAdditionalFilters,
      ...cleanedQueryParams
    } = filtersFromQueryParams(location.query);
    router.replace({
      ...location,
      query: {
        ...cleanedQueryParams,
        ...otherPageFunctions,
        ...additionalFilters,
      },
    });
  }

  const filters = filtersFromQueryParams(location.query);
  return <PlayerStatsPage onFilterChange={handleFilterChange} filters={filters} />;
}

function filtersFromQueryParams(queryParams: ?{|+[string]: ?string|}): Filters {
  let filters: Filters = {
    sortColumn: "iGoals",
    sortDirection: "desc",
    strength: "EV",
    season: "20192020",
    league: null,
    playerDraftAge: null,
    after: null,
    report: ReportTypes.COUNTING,
    additionalFilters: {
      minToiPerGame: 600,
      minGamesPlayed: 10,
    },
  };
  if (queryParams == null) {
    return filters;
  }
  if (typeof queryParams.sortColumn === "string") {
    filters = {...filters, sortColumn: queryParams.sortColumn};
  }

  if (typeof queryParams.sortDirection === "string") {
    const sortDirection = narrowValueToEnumOrNull(SortDirectionsEnum, queryParams.sortDirection);
    if (sortDirection != null) {
      filters = {...filters, sortDirection};
    }
  }

  if (typeof queryParams.strength === "string") {
    filters = {...filters, strength: queryParams.strength};
  }

  if (typeof queryParams.season === "string") {
    filters = {...filters, season: queryParams.season};
  }

  if (typeof queryParams.league === "string") {
    filters = {...filters, league: queryParams.league};
  }

  if (typeof queryParams.playerDraftAge === "string") {
    const playerDraftAge = parseInt(queryParams.playerDraftAge, 10);
    if (!isNaN(playerDraftAge)) {
      filters = {...filters, playerDraftAge: playerDraftAge};
    }
  }

  if (typeof queryParams.after === "string") {
    filters = {...filters, after: queryParams.after};
  }

  if (typeof queryParams.report === "string") {
    const report = narrowValueToEnumOrNull(ReportTypes, queryParams.report);
    if (report != null) {
      filters = {...filters, report};
    }
  }

  if (typeof queryParams.minToiPerGame === "string") {
    const minToiPerGame = parseFloat(queryParams.minToiPerGame);
    if (!isNaN(minToiPerGame)) {
      filters = {
        ...filters,
        additionalFilters: {
          ...filters.additionalFilters,
          minToiPerGame: minToiPerGame,
        },
      };
    }
  }

  if (typeof queryParams.minGamesPlayed === "string") {
    const minGamesPlayed = parseInt(queryParams.minGamesPlayed, 10);
    if (!isNaN(minGamesPlayed)) {
      filters = {
        ...filters,
        additionalFilters: {...filters.additionalFilters, minGamesPlayed: minGamesPlayed},
      };
    }
  }

  return filters;
}
