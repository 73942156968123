/* @flow strict */

import * as React from "react";
import {UID} from "react-uid";

export type Option = {
  +display?: string,
  +value: string,
};
type Props = {|
  +label?: string,
  +options: $ReadOnlyArray<Option>,
  +value: ?string,
  onSelect(Option): void,
|};

export default class Select extends React.PureComponent<Props> {
  handleSelect = (event: SyntheticEvent<HTMLSelectElement>): void => {
    const {options, onSelect} = this.props;
    const selectedValue = event.currentTarget.value;
    for (let option of options) {
      if (option.value === selectedValue) {
        return onSelect(option);
      }
    }
  };

  render() {
    const {label, options, value} = this.props;

    return (
      <UID>
        {(id: string) => (
          <div className="input-group mb-3">
            {label != null && (
              <div className="input-group-prepend">
                <label className="input-group-text" htmlFor={id}>
                  {label}
                </label>
              </div>
            )}
            <select className="custom-select" id={id} onChange={this.handleSelect} value={value}>
              <option disabled>Choose...</option>
              {options.map((option) => (
                <option key={`${id}-${option.value}`} value={option.value}>
                  {option.display == null ? option.value : option.display}
                </option>
              ))}
            </select>
          </div>
        )}
      </UID>
    );
  }
}
