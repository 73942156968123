/*
@flow strict-local
 */

import * as React from "react";
import {createFragmentContainer, graphql} from "react-relay";

import Table, {type SortDirection, type ColumnDefinition} from "components/table/Table";
import type {PlayerStats_seasons as Seasons} from "./__generated__/PlayerStats_seasons.graphql";

type Props = {
  +seasons: Seasons,
};

const UNKNOWN = "🥶";
const COLUMNS = [
  {columnName: "Season", key: "season", sortable: false},
  {columnName: "Age", key: "draftAge", sortable: false},
  {columnName: "GP", key: "gamesPlayed", sortable: false},
  {columnName: "iS", key: "iShots", sortable: false},
  {columnName: "iG", key: "iGoals", sortable: false},
  {columnName: "iS%", key: "shootingPerc", sortable: false},
  {columnName: "iA1", key: "iA1", sortable: false},
  {columnName: "iA2", key: "iA2", sortable: false},
  {columnName: "iA", key: "iA", sortable: false},
  {columnName: "GF", key: "goalsFor", sortable: false},
  {columnName: "GA", key: "goalsAgainst", sortable: false},
  {columnName: "GF%", key: "goalsForPerc", sortable: false},
];
function PlayerStats({seasons}: Props) {
  if (seasons.length) {
    return <Table dataRows={seasons.map(seasonToRow)} columns={COLUMNS} />;
  } else {
    return <div>No data</div>;
  }
}
function seasonToRow({
  id,
  season,
  draftAge,
  gamesPlayed,
  iShots,
  iGoals,
  shootingPerc,
  iA1,
  iA2,
  iA,
  goalsFor,
  goalsAgainst,
  goalsForPerc,
}: {
  +id: string,
  +season: string,
  +draftAge: ?number,
  +gamesPlayed: number,
  +iShots: number,
  +iGoals: number,
  +shootingPerc: ?number,
  +iA1: number,
  +iA2: number,
  +iA: number,
  +goalsFor: number,
  +goalsAgainst: number,
  +goalsForPerc: ?number,
}): {|
  +id: string,
  +season: string,
  +draftAge: string,
  +gamesPlayed: string,
  +iShots: string,
  +iGoals: string,
  +shootingPerc: string,
  +iA1: string,
  +iA2: string,
  +iA: string,
  +goalsFor: string,
  +goalsAgainst: string,
  +goalsForPerc: string,
|} {
  return {
    id,
    season: formatSeasonForDisplay(season),
    draftAge: draftAge == null ? UNKNOWN : draftAge.toString(),
    gamesPlayed: gamesPlayed.toString(),
    iShots: iShots.toString(),
    iGoals: iGoals.toString(),
    shootingPerc: shootingPerc != null ? formatPercForDisplay(shootingPerc) : UNKNOWN,
    iA1: iA1.toString(),
    iA2: iA2.toString(),
    iA: iA.toString(),
    goalsFor: goalsFor.toString(),
    goalsAgainst: goalsAgainst.toString(),
    goalsForPerc: goalsForPerc != null ? formatPercForDisplay(goalsForPerc) : UNKNOWN,
  };
}
function formatPercForDisplay(perc: number): string {
  const fixedLength = perc.toFixed(2);
  return `${fixedLength}%`;
}
function formatSeasonForDisplay(season: string): string {
  return `${season.substring(0, 4)}-${season.substring(4)}`;
}
export default createFragmentContainer(PlayerStats, {
  seasons: graphql`
    fragment PlayerStats_seasons on PlayerSeason @relay(plural: true) {
      season
      gamesPlayed
      id
      draftAge
      gamesPlayed
      iShots
      iGoals
      shootingPerc
      iA1
      iA2
      iA
      goalsFor
      goalsAgainst
      goalsForPerc
    }
  `,
});
