// @flow strict-local

import * as React from "react";
import {Resolver} from "found-relay";

import environment from "environment";

import Router from "Router";
import Header from "components/Header";

export default function App() {
  return (
    <>
      <Header />
      <Router resolver={new Resolver(environment)} />
    </>
  );
}
