// @flow strict

export type Size = $Keys<typeof whitespaceSizes>;

const whitespaceSizes: {|
  +none: 0,
  +xs: 4,
  +s: 8,
  +m: 12,
  +l: 20,
  +xl: 32,
  +xxl: 40,
|} = Object.freeze({
  none: 0,
  xs: 4,
  s: 8,
  m: 12,
  l: 20,
  xl: 32,
  xxl: 40,
});

export default whitespaceSizes;
