/*
 * @flow strict-local
 */

import * as React from "react";
import {createFragmentContainer, graphql} from "react-relay";
import narrowConnection from "tools/narrowConnection";

import DashboardTableCard from "components/dashboard/DashboardTableCard";

import type {DashboardTopGoalscorersCard_topGoalScorersQuery as TopGoalScorersQuery} from "./__generated__/DashboardTopGoalscorersCard_topGoalScorersQuery.graphql";

type Props = {|
  +topGoalScorersQuery: TopGoalScorersQuery,
|};

function DashboardTopGoalscorersCard({topGoalScorersQuery}: Props) {
  const dataRows = narrowConnection(topGoalScorersQuery.topGoalScorers).map(
    ({id, player: {fullName}, gamesPlayed, iGoals}) => ({
      id,
      object: fullName,
      gamesPlayed: gamesPlayed.toString(),
      iGoals: iGoals.toString(),
    }),
  );
  return (
    <DashboardTableCard
      objectType="Player"
      dataRows={dataRows}
      title="Top Goalscorers"
      statColumns={[
        {columnName: "GP", key: "gamesPlayed"},
        {columnName: "G", key: "iGoals"},
      ]}
    />
  );
}

export default createFragmentContainer(DashboardTopGoalscorersCard, {
  topGoalScorersQuery: graphql`
    fragment DashboardTopGoalscorersCard_topGoalScorersQuery on Query {
      topGoalScorers: playerSeasonAggregations(
        first: 5
        sortColumn: "iGoals"
        sortDirection: "desc"
        fromSeason: "20192020"
        toSeason: "20192020"
      ) {
        edges {
          node {
            id
            player {
              fullName
            }
            gamesPlayed
            iGoals
          }
        }
      }
    }
  `,
});
